import React from 'react';
import {
  NavLink,
  useHistory,
} from 'react-router-dom';
import {
  homepageRoute,
  loginRoute,
  dashboardRoute,
} from "../../constants/routes";
import {signout} from '../../util/authHelper';

const NavBar = () => {
  const history = useHistory();

  function handleLogout() {
    signout();
    history.push('/');
  }

  return (
    <nav>
      <ul>
        <li>
          <NavLink to={homepageRoute}>Home</NavLink>
        </li>
        <li>
          <NavLink to={loginRoute}>Login</NavLink>
        </li>
        <li>
          <NavLink to={dashboardRoute}>Dashboard</NavLink>
        </li>
        <li>
          <button onClick={handleLogout} className="pure-button">Logout</button>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;