import React, {useState} from 'react';

import LinkButton from '../../components/LinkButton';
import {createUser} from '../../util/authHelper';

const NewUser = ({onChangeType, onSuccess}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleSubmit(event) {
    if(password === confirmPassword && email) {
      createUser({username: email, password}).then(() => {
        onSuccess();
      }).catch((error) => {
        console.error(error);
      });
    }
    event.preventDefault();
  }

  function handleEmailChange({target: {value}}) {
    setEmail(value);
  }

  function handlePasswordChange({target: {value}}) {
    setPassword(value);
  }

  function handleConfirmPasswordChange({target: {value}}) {
    setConfirmPassword(value);
  }

  return (
    <div>
      <h2>Sign up</h2>

      <form className="pure-form pure-form-stacked" onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input name="email" id="email" type="email" onChange={handleEmailChange} />
        <label htmlFor="password">Password</label>
        <input name="password" id="password" type="password" onChange={handlePasswordChange} />
        <label htmlFor="confirmPassword">Confirm password</label>
        <input name="confirmPassword" id="confirmPassword" type="password" onChange={handleConfirmPasswordChange} />
        <input type="submit" className="pure-button pure-button-primary" />
      </form>
      
      <div>
        Already have an account? <LinkButton onClick={onChangeType}>Sign in here!</LinkButton>
      </div>
    </div>
  )
}

export default NewUser;