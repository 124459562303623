const axios = require('axios');
const {getAuthToken} = require('../authHelper');

export const sendRequest = ({headers, ...args}) => {
  return sendUnauthenticatedRequest({
    headers: {
      Authentication: `Bearer ${getAuthToken()}`,
      ...headers,
    },
    ...args,
  })
}

export const sendUnauthenticatedRequest = (args) => {
  return axios({
    baseURL: 'https://api.forgeofmars.com',
    timeout:10000,
    responseType: 'json',
    ...args,

  })
}
