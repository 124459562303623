import React, {useState} from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {homepageRoute} from '../../constants/routes';
import ExistingUser from './ExistingUser';
import NewUser from './NewUser';
import RecoverUser from './RecoverUser';

const Login = () => {
  const [showType, setShowType] = useState("existing");
  let currentDisplay;
  const history = useHistory();
  const location = useLocation();

  const {from} = location.state || {from: {pathname: homepageRoute}};
  
  function handleSuccess() {
    history.replace(from);
  }

  function handleChangeType(event) {
    const {target:{name}} = event;
    switch (name) {
      case "newAccount":
        setShowType("signup");
        break;
      case "recoverAccount":
        setShowType("recover");
        break;
      case "existingAccount":
      default:
        setShowType("existing");
        break;
    }
    event.preventDefault();
  }

  switch (showType) {
    case "signup":
      currentDisplay = <NewUser onChangeType={handleChangeType} onSuccess={handleSuccess} />
      break;
    case "recover":
      currentDisplay = <RecoverUser onChangeType={handleChangeType} />
      break;
    case "existing":
    default:
      currentDisplay = <ExistingUser onChangeType={handleChangeType} onSuccess={handleSuccess} />
  }

  return (
    <div className="loginRoute">
      {currentDisplay}
    </div>
  );
};

export default Login;