import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import {
  dashboardRoute,
  homepageRoute,
  loginRoute,
} from "./constants/routes";

import Home from './pages/home';
import NavBar from './components/navbar';
import PrivateRoute from './components/privateRoute';
import DoesNotExist from './pages/doesNotExist';
import Login from './pages/login';
import Dashboard from './pages/dashboard';

import './App.css';

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Switch>
          <Route exact path={homepageRoute}>
            <Home />
          </Route>
          <Route path={loginRoute}>
            <Login />
          </Route>
          <PrivateRoute path={dashboardRoute}>
            <Dashboard />
          </PrivateRoute>
          <DoesNotExist />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
