import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isSessionAuthenticated} from "../../util/authHelper";

const PrivateRoute = ({children, ...rest}) => {
  return (
    <Route
      {...rest}
      render={({location}) => {
        return isSessionAuthenticated() ? 
          children
        :
          <Redirect to={{
            pathname: '/login',
            state: {from: location}
          }}
        />
      }}
    />
  );
};

export default PrivateRoute;