import { sendUnauthenticatedRequest } from "../requestHelper";

let isAuthenticated = false;
// eslint-disable-next-line
let userDetails = null;

function authenticate({username, password}) {
  console.log(username, password);
  isAuthenticated = true;
  userDetails = {
    username,
  }
  return Promise.resolve();
}
function signout() {
  isAuthenticated = false;
  userDetails = null;
  return Promise.resolve();
}
function recover(email) {
  console.log(email);
  return Promise.resolve();
}
function getAuthToken() {
  return 
}
function isSessionAuthenticated() {
  return isAuthenticated;
}
function createUser({username, password} = {}) {
  if (!username || !password) {
    return Promise.reject('username and password are required');
  }
  return sendUnauthenticatedRequest({
    url:'/users/',
    method: 'put',
    data: {
      username,
      password,
    }
  }).then(() => {
    isAuthenticated = true;
    userDetails = {
      username,
    };
  });
}

export {
  authenticate,
  signout,
  recover,
  getAuthToken,
  isSessionAuthenticated,
  createUser,
};

export default {
  authenticate,
  signout,
  recover,
  getAuthToken,
  isSessionAuthenticated,
  createUser,
};
